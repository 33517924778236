<template>
    <div id="login">
        <div class="leftLogin">
            <div class="inputFormLogin">
                <div class="welcomeBack" :style="bgyPos">
                    <strong>Welcome To Admin's Web</strong> <br>
                    <p class="y-black-text welcomeInfo">Please Login to Continue</p>
                </div>
                <div class="bodyForm">
                    <div class="y-inputField">
                        <div class="y-labelText">
                            Username
                        </div>
                        <div class="y-hasIcon-right y-lr-size">
                            <input type="text" v-model="item.username" :class="errorUsername ? 'y-red-outline' : ''" @keypress.enter="submitLogin()" placeholder="username">
                        </div>
                        <div class="y-errorText y-red-text" :style="errorUsername ? 'opacity: 1 !important;': ''">
                            {{usernameErrMsg}}
                        </div>
                    </div>

                    <div class="y-inputField">
                        <div class="y-labelText">
                            Password 
                        </div>
                        <div class="y-hasIcon-right y-lr-size">
                            <input :type="showpassword ? 'text' : 'password'" v-model="item.password"  :class="errorPassword ? 'y-red-outline' : ''" @keyup="errorPassword = false" @keypress.enter="submitLogin()" placeholder="password">
                            <div class="y-inputIcon r-center-flex">
                                <img :src="require(`@/assets/icons/login/${showpassword ? 'eyeClose' : 'eyeOpen'}.svg`)" alt="" @click="showpassword = !showpassword">    
                            </div>
                        </div>
                        <div class="y-errorText y-red-text" :style="errorPassword ? 'opacity: 1 !important;': ''">
                            {{passwordErrMsg}}
                        </div>
                    </div>
                </div>
                <div class="loginFooter">
                    <button class="y-green-btn y-is-fullwidth y-lr-size" @click.prevent="submitLogin()">
                        <span v-if="!load">Login</span>
                        <img :src="require('@/assets/loadingDot.svg')" alt style="width: 50px;" v-else />
                    </button>
                </div>
            </div>
        </div>
        <div class="rightLogin">
            <div class="backgroundImg">
            </div>
        </div>
    </div>
</template>
<style lang="scss">
    .y-forgetText{
        margin-left: auto;
        font-weight: 500;
    }
</style>
<script>
import { mapGetters, mapActions } from "vuex"
import common from "@/mixins/function/common"
import tableMethods from '@/mixins/tableMethods'
export default {
    mixins: [common, tableMethods],
    data() {
        return{
            window: {
                width: 0,
                height: 0
            },
            error: false,
            showpassword: false,
            errorUsername: false,
            errorPassword: false,
            message: "",
            load: false,
            passwordErrMsg: 'Password is missing',
            usernameErrMsg: 'Username is missing'
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    computed: {
        ...mapGetters({
            item: 'auth/getItem'
        }),
        bgyPos(){
            var x = this.window.width
            var x1 = 300
            var y1 = -40
            var x2 = 800
            var y2 = -270
            var position = (((x-x1)*(y2-y1))/(x2-x1))+y1;
            return `background-position-y: ${position}px`
        }
    },
    methods: {
        ...mapActions({
            login: 'auth/login',
        }),
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        validateInput(){
            var error = 0;
            if(this.item.password == '' || this.item.password == null){
                this.errorPassword = true
                this.passwordErrMsg = 'Password is missing'
                error +=1 
            }
            if(this.item.username == '' || this.item.username == null){
                this.errorUsername = true
                this.usernameErrMsg = 'Username is missing'
                error +=1 
            }
            return error
        },
        async submitLogin() {
            if(this.validateInput() != 0){
                return 
            }else{
                this.errorPassword = false
                this.errorUsername = false
            }
            this.load= true
            await this.login(this.item)
            const response = this.getResponse('auth')
            if(response.status == 0){
                this.showSnackbar ({
                    type: "error",
                    text: response.msg
                })
                this.load = false;
            }else{
                if(this.$store.getters['getisLoggedIn']){
                    setTimeout(function(){ location.reload(); }, 3000);
                }else{
                    this.load = false;
                    this.errorUsername = true
                    this.usernameErrMsg= `Wrong username or password`
                    this.errorPassword = true
                    this.passwordErrMsg= `Wrong username or password`
                }
            }
        }
    }
}
</script>